import Text from "./Text";

type ButtonProps = {
  onClick: () => void;
  text: string;
  className?: string;
};

const Button = ({ onClick, text, className }: ButtonProps) => {
  return (
    <button onClick={onClick} className={`w-full bg-gradient-to-r from-[#547AFF] to-[#413DFF] rounded-lg p-2 ${className}`}>
      <Text className="text-lg text-white text-center">{text}</Text>
    </button>
  )
};

export default Button;