class API {
  _base = "https://api-opera.nymz.dev";
  get temporaryUsers() {
    return {
      post: async (payload: any) => {
        return this._post('/temporary-users', payload);
      },
      put: async (temporary_user_id: string, payload: any) => {
        return this._put(`/temporary-users/${temporary_user_id}`, payload);
      },
      get: async (temporary_user_id: string) => {
        return this._get(`/temporary-users/${temporary_user_id}`);
      },
      verify: async (temporary_user_id: string, payload: any) => {
        return this._put(`/temporary-users/${temporary_user_id}/verify`, payload);
      },
    }
  }
  get users() {
    return {
      validate: async (payload: any) => {
        return this._post('/users/validate-phone', payload);
      },
      post: async (payload: any) => {
        return this._post('/users', payload);
      },
      login: async (payload: any) => {
        return this._post('/users/login', payload);
      },
      code: async (payload: any) => {
        return this._post('/users/login/2fa', payload);
      },
    }
  }
  get images() {
    return {
      post: async (base64: string) => {
        return this._post('/images/base64', { base64: base64.split('base64,')[1] });
      }
    }
  }
  async _baseRequest(method: string, url: string, data = null, has_file = false) {
    var headers = new Headers();
    headers.append("Content-Type", "application/json");
    var requestOptions: any = {
      method,
      headers,
      redirect: 'follow'
    };
    if (data && !has_file) {
      console.log({data});
      requestOptions.body = JSON.stringify(data);
    }
    if (has_file) {
      requestOptions.body = data;
      headers.append('Content-Type', "multipart/form-data");
    }
    let response;
    try {
      response = await fetch(url, requestOptions)
      response = await response.json();
    } catch (error) {
      response = {
        success: false,
        data: { message: "The request did not succeed" },
      };
      console.error({error});
    }
    return response;
  }

  async _get(path: string) {
    return this._baseRequest("GET", `${this._base}${path}`);
  }

  async _put(path: string, data: any, has_file = false) {
    return this._baseRequest("PUT", `${this._base}${path}`, data, has_file);
  }

  async _delete(path: string) {
    return this._baseRequest("DELETE", `${this._base}${path}`);
  }

  async _post(path: string, data: any = null, has_file = false) {
    return this._baseRequest("POST", `${this._base}${path}`, data, has_file);
  }
}

export default API;