import { useCallback, useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import Loading from "../components/Loading";
import storage from '../services/storage';
import AppContext from "../context/app";
import Button from "../components/Button";
import BaseScreen from "../components/BaseScreen";
import IconButton from "../components/IconButton";
import Text from "../components/Text";
import { useNavigate } from "react-router-dom";
import API from "../services/API";
import TemporaryUser from "../types/TemporaryUser";

let schema = object({
  address: string().required(),
  postal_code: string().min(7).max(7).required(),
});

const api = new API();

const Address = () => {

  const { setErrorMessage, temporaryUser } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { control, handleSubmit, formState: { errors } }: any = useForm({
    resolver: yupResolver(schema),
    defaultValues: { ...temporaryUser }
  });

  useEffect(() => {
    if (temporaryUser._id) {
      navigate('/scan')
    }
  }, [temporaryUser]);

  const createTemporaryUser = async (data: Partial<TemporaryUser>) => {
    setIsLoading(true);
    const response = await api.temporaryUsers.post({ ...temporaryUser, ...data });
    if (response.success === false) {
      setIsLoading(false);
      setErrorMessage(response.data.message);
      navigate('/error');
      return;
    }
    const temporaryUserID = response.data.temporary_user_id;
    await storage.storeData('temporary_user_id', temporaryUserID);
    setIsLoading(false);
    navigate('/scan');
  };

  const Input = useCallback(({ name, type = "default" }: { name: any, type?: string }) => {
    return (
      <>
        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ field }) => <input {...field}  type={type} className="w-full p-3 text-black rounded-lg text-right bg-[#ECEEEF]" />}
          name={name}
        />
        {errors[name] && <Text className="text-[#FF554A] mt-1">השדה חסר או אינו נכון</Text>}
      </>
    )
  }, [errors]);

  return (
    <BaseScreen>
      <Loading isLoading={isLoading} />
      <div className="flex flex-row justify-between items-center mb-5">
        <Text>הרשמה</Text>
        <IconButton name="arrow_back_ios" onClick={() => { navigate(-1)}} />
      </div>
      <div className="flex flex-col h-full">
        <Text className="text-3xl mb-2">כתובת, וזזנו!</Text>
        <div>
          <Text className="text-lg text-[#B9BABE] my-2">כתובת</Text>
          <Input name="address" />
        </div>
        <div className="mb-auto">
          <Text className="text-lg text-[#B9BABE] my-2">מיקוד</Text>
          <Input type="tel" name="postal_code" />
        </div>
        <Button onClick={handleSubmit(createTemporaryUser)} text="המשך" />
      </div>
    </BaseScreen>
  )
};

export default Address;
