import { useCallback, useContext } from "react";
import AppContext from "../context/app";
import BaseScreen from "../components/BaseScreen";
import { useNavigate } from "react-router-dom";
import Icon from "@mui/material/Icon";
import Text from "../components/Text";
import Button from "../components/Button";
import storage from "../services/storage";

const Success = () => {
  const { successMessage, setTemporaryUser } = useContext(AppContext);
  const completeRegistration = useCallback(async() => {
    await storage.remove('temporary_user_id');
    setTemporaryUser({
      first_name_hebrew: "",
      last_name_hebrew: "",
      first_name_english: "",
      last_name_english: "",
      email: "",
      id_number: "",
      gender: "",
      address: "",
      postal_code: "",
    });
    navigate('/');
  }, []);
  const navigate = useNavigate();
  return (
    <BaseScreen>
      <div className="flex items-center justify-center flex-col">
        <div className="flex items-center justify-center w-[70px] h-[70px] mb-10 rounded-full bg-[#25C26E]/25">
            {/* todo: improve icon background style, so it will look like error screen */}
            <Icon fontSize="large" className="w-fit text-[#25C26E]">done</Icon>
        </div>
        <Text className="text-center text-3xl">בוצע בהצלחה</Text>
        <Text className="text-center text-xl">{successMessage}</Text>
      </div>
      <Button text="חזרה" onClick={completeRegistration} className="mt-auto rounded-lg bg-[#4F6AFF]" />
    </BaseScreen>
  )
};

export default Success;