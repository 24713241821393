import React, { useCallback, useContext, useState } from "react";
import Button from "../components/Button";
import AppContext from "../context/app";
import BaseScreen from '../components/BaseScreen';
import Text from '../components/Text';
import IconButton from "../components/IconButton";
import { useLocation, useNavigate } from "react-router-dom";
import { object, string } from 'yup';
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import API from "../services/API";

const api = new API();

// Fix: A component is changing an uncontrolled input to be controlled. This is likely caused by the value changing from undefined to a defined value, which should not happen. Decide between using a controlled or uncontrolled input element for the lifetime of the component. More info: https://reactjs.org/link/controlled-components
// both in code.tsx & login.tsx

let schema = object({
  code: string().min(6).max(6).required(),
});

const Code = () => {

  const { setErrorMessage } = useContext(AppContext);
  const { control, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();
  const { state } = useLocation();
  const { id_number, password } = state; // Read values passed on state

  const loginWithCode = async (data: any) => {
    const response = await api.users.code({ ...data, id_number, password });
    if (response.success === false) {
      setErrorMessage(response.data.message);
      navigate('/error');
      return;
    }
  };

  const Input = useCallback(({ name, type = "default" }: { name: string, type?: string }) => {
    return (
      <>
        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ field }) => <input {...field} type={type} className="w-full p-3 text-black rounded-lg text-right bg-[#ECEEEF]" />}
          name={name}
        />
        {errors[name] && <Text className="text-[#FF554A] mt-1">השדה חסר או אינו נכון</Text>}
      </>
    )
  }, [control, errors]);

  return (
    <BaseScreen>
      <div className="flex flex-row justify-between items-center mb-5">
        <Text>התחברות</Text>
        <IconButton name="arrow_back_ios" onClick={() => navigate(-1)} />
      </div>
      <div className="flex flex-col h-full">
        <div>
          <Text className="text-lg text-[#B9BABE] my-2">קוד</Text>
          <Input type="password" name="code" />
        </div>
        <div className="mt-auto">
          <Button onClick={handleSubmit(loginWithCode)} text="התחבר" />
        </div>
      </div>
    </BaseScreen>
  )
}


export default Code;