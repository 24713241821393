import React from "react";
import Icon from '@mui/material/Icon';

type IconButtonProps = {
  onClick: () => void;
  name: string;
  css?: string,
  size?: number;
};

const IconButton = ({ onClick, name, size = 30, css = "" }: IconButtonProps) => {
  return (
    <button onClick={onClick} className={css}>
      <Icon>{name}</Icon>
    </button>
  )
};

export default IconButton;