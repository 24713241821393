import Modal from "./Modal";
import Text from "./Text";
import Button from "./Button";
import { useCallback } from "react";
import storage from "../services/storage";
import { useContext } from "react";
import AppContext from "../context/app";
import Icon from '@mui/material/Icon';
import { useNavigate } from "react-router-dom";

type CantGoBackProps = {
  isVisible: boolean;
  setIsVisible: (value: boolean) => void;
};

const CantGoBack = ({ isVisible, setIsVisible }: CantGoBackProps) => {
  const { setTemporaryUser } = useContext(AppContext);
    const navigate = useNavigate();
    const resetAll = useCallback(() => {
    storage.remove('temporary_user_id');
    setTemporaryUser({
      first_name_hebrew: "",
      last_name_hebrew: "",
      first_name_english: "",
      last_name_english: "",
      email: "",
      id_number: "",
      gender: "",
      address: "",
      postal_code: "",
    });
    navigate('/');
  }, []);
  return (
    <Modal showModal={isVisible}>
      <div className="p-5">
        <div className="text-center w-fit m-auto text-[#FF554A]">
          <Icon fontSize="large">warning</Icon>
        </div>
        <Text className="my-5 text-xl">אין אפשרות לחזור לעריכת הפרטים, האם תרצה להרשם מחדש?</Text>
        <div className="flex flex-row">
          <div className="flex-1 ml-1">
            <Button className="from-[#FF3D3D] to-[#FF7D54]" onClick={resetAll} text="כן" />
          </div>
          <div className="flex-1 mr-1">
            <Button  className="from-[#ACACB0] to-[#ACACB0]" onClick={() => setIsVisible(false)} text="לא" />
          </div>
        </div>
      </div>
    </Modal>
  )
};
export default CantGoBack;