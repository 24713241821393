import Modal from 'react-modal';

type ModalProps = {
  showModal: boolean;
  children: JSX.Element | JSX.Element[]
};

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

export default ({ showModal, children }: ModalProps) => {
  return (
    <Modal isOpen={showModal} style={customStyles}>
      {children}
    </Modal>
  );
}