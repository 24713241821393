import { useContext } from "react";
import AppContext from "../context/app";
import BaseScreen from "../components/BaseScreen";
import { useNavigate } from "react-router-dom";
import Icon from "@mui/material/Icon";
import Button from "../components/Button";
import Text from "../components/Text";

const Error = () => {
    const navigate = useNavigate();
    const { errorMessage } = useContext(AppContext)
    return (
        <BaseScreen>
            <div className="flex items-center justify-center flex-col">
                <div className="flex items-center justify-center w-[70px] h-[70px] mb-10 rounded-full bg-[#FF554A]/25">
                    <Icon fontSize="large" className="w-fit text-[#FF554A]">error</Icon>
                </div>
                <Text className="text-center text-3xl">שגיאה</Text>
                <Text className="text-center text-xl">{errorMessage}</Text>
            </div>
            <Button text="חזרה" onClick={() => navigate(-1)} className="mt-auto rounded-lg bg-[#4F6AFF]" />
        </BaseScreen>
    )
};

export default Error;