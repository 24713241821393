type BaseScreenProps = {
  children: JSX.Element | JSX.Element[] | any
}

const BaseScreen = ({ children }: BaseScreenProps) => {
  return (
    <div className="flex flex-col max-h-screen h-screen bg-gradient-to-r from-[#F6F6F6] to-[#fff] p-5">
      {children}
    </div>
  )
};

export default BaseScreen;