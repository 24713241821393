const remove = async (key: string) => {
    try {
        return localStorage.removeItem(key)
    } catch (error) {
        console.error(error);
    }
}

const storeData = async (key: string, value: string) => {
    try {
        return localStorage.setItem(key, value)
    } catch (error) {
        console.error(error);
    }
}

const getData = async (key: string) => {
    try {
        return localStorage.getItem(key)
    } catch (error) {
        console.error(error);
    }
}

const storeJsonData = async (key: string, value: any) => {
    try {
        const jsonValue = JSON.stringify(value)
        return localStorage.setItem(key, jsonValue)
    } catch (error) {
        console.error(error);
    }
}

const getJsonData = async (key: string) => {
    try {
        const value = await localStorage.getItem(key)
        if (!value) {
            console.error('no item found');
            return;
        }
        return JSON.parse(value);
    } catch (error) {
        console.error(error);
    }
}

export default { storeJsonData, getJsonData, storeData, getData, remove }