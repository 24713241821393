import { useCallback, useContext, useEffect, useState } from "react";
import API from "../services/API";
import storage from "../services/storage";
import AppContext from "../context/app";
import IconButton from "../components/IconButton";
import Text from "../components/Text";
import BaseScreen from "../components/BaseScreen";
import Button from "../components/Button";
import Loading from "../components/Loading";
import CantGoBack from "../components/CantGoBack";
import { useLocation, useNavigate } from "react-router-dom";

const api = new API();

const Photo = () => {

  const { temporaryUser, setErrorMessage, setTemporaryUser } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false); // should be true by default
  const [showGoBack, setGoBack] = useState(false);
  const { state: { photo } } = useLocation();
  const navigate = useNavigate();

  const uploadPicture = useCallback(async () => {
    if (!photo) return;
    let response = await api.images.post(photo)
    console.log({ response });
    if (response.success === false) {
      setErrorMessage(response.data.message);
      navigate('/error');
      return;
    }
    return response.data.path;
  }, [photo]);

  const confirmPicture = useCallback(async () => {
    setIsLoading(true);
    const image = await uploadPicture();
    if (!image) {
      // todo: tel the user image failed to upload
      setIsLoading(false);
      return;
    }
    const temporary_user_id = await storage.getData('temporary_user_id');
    if (!temporary_user_id) {
      // todo: tel the user...
      console.log('no temp id saved');
      return;
    }
    let response = await api.temporaryUsers.verify(temporary_user_id, { image })
    if (response.success === false) {
      setErrorMessage("אימות נכשל, אנא נסה שנית");
      setIsLoading(false);
      navigate('/error');
      return;
    }
    response = await api.temporaryUsers.get(temporary_user_id);
    if (response.success === false) {
      setErrorMessage("אימות נכשל, אנא נסה שנית");
      setIsLoading(false);
      navigate('/error');
      return;
    }
    setTemporaryUser(response.data.temporaryUser);
    setIsLoading(false);
    navigate('/scan');
  }, [photo]);

  const retakePicture = useCallback(() => {
    navigate('/scan');
  }, []);

  useEffect(() => {
    if (temporaryUser.credit_verified) {
      navigate('/password')
    }
  }, [temporaryUser]);

  // todo: when verification failed navigate to scan
  return (
    <BaseScreen>
      <Loading isLoading={isLoading} />
      <CantGoBack isVisible={showGoBack} setIsVisible={setGoBack} />
      <div className="flex flex-row justify-between items-center mb-5">
        <Text>הרשמה</Text>
        <IconButton name="arrow_back_ios" onClick={() => setGoBack(true)} />
      </div>
      <div className="h-full w-full">
        <div className="flex flex-col h-full">
          <div className="max-w-full bg-gray-500 rounded h-[190px] mb-2">
            <img
              className="aspect-auto"
              style={{ borderColor: "#4A5AFF", borderWidth: 1, borderRadius: 10, width: '100%', height: '100%' }}
              src={photo}
            />
          </div>
          <Text className="text-lg text-center mb-auto font-bold">רואים בבירור את הפרטים בתמונה?</Text>
          <Button className="mb-2" onClick={confirmPicture} text="כן, לשלב הבא!" />
          <Button onClick={retakePicture} text="לא, צלם מחדש!" />
        </div>
      </div>
    </BaseScreen>
  )
}

export default Photo;