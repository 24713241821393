import { createContext } from 'react';
import type TemporaryUser from '../types/TemporaryUser';

type AppContext = {
  errorMessage: string,
  setErrorMessage: (value: string) => void,
  successMessage: string,
  setSuccessMessage: (value: string) => void,
  temporaryUser: TemporaryUser,
  setTemporaryUser: (value: TemporaryUser) => void,
}
const defaultValues: AppContext = {
  errorMessage: "",
  setErrorMessage: (value: string) => { },
  successMessage: "",
  setSuccessMessage: (value: string) => { },
  temporaryUser: {
    first_name_hebrew: "",
    last_name_hebrew: "",
    first_name_english: "",
    last_name_english: "",
    email: "",
    id_number: "",
    gender: "",
    address: "",
    postal_code: "",
  },
  setTemporaryUser: (value: TemporaryUser) => { },
}
export default createContext(defaultValues);