import Modal from 'react-modal';
import ReactLoading from 'react-loading';

type LoadingProps = {
    isLoading: boolean;
};

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

const Loading = ({ isLoading }: LoadingProps) => {

    return (
        <Modal
            isOpen={isLoading}
            style={customStyles}
        >
            <ReactLoading type="spin" color={"#000"} />
        </Modal>
    );
}

export default Loading;