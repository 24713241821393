import React, { useCallback, useContext, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import AppContext from "../context/app";
import Button from "../components/Button";
import BaseScreen from "../components/BaseScreen";
import Text from "../components/Text";
import IconButton from "../components/IconButton";
import { useNavigate } from "react-router-dom";

let schema = object({
  email: string().email().required(),
  id_number: string().min(9).max(9).required(),
  gender: string().oneOf(["m", "f"]).required(),
});

const Details = () => {

  const { temporaryUser, setTemporaryUser } = useContext(AppContext);
  const navigate = useNavigate();
  const { control, setValue, handleSubmit, formState: { errors } }: any = useForm({
    resolver: yupResolver(schema),
    defaultValues: { ...temporaryUser }
  });

  useEffect(() => {
    if (temporaryUser._id) {
      navigate('/scan')
    }
  }, [temporaryUser]);

  const onSubmit = async (data: any) => {
    setTemporaryUser({ ...temporaryUser, ...data });
    navigate('/address');
  }; // todo: add use callback

  const Input = useCallback(({ name, type = "default" }: { name: any, type?: string }) => {
    return (
      <>
        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ field }) => <input {...field}  type={type} className="w-full p-3 text-black rounded-lg text-right bg-[#ECEEEF]" />}
          name={name}
        />
        {errors[name] && <Text className="text-[#FF554A] mt-1">השדה חסר או אינו נכון</Text>}
      </>
    )
  }, [errors]);

  const GenderSelection = useCallback(() => {
    return (
      <>
        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { value } }) => (
            <div className="flex flex-row">
              <button onClick={() => setValue('gender', "m")} className={value === 'm' ? "flex-1 border border-[#4F69FF] bg-[#ECEEEF] p-3 ml-1 rounded-lg" : "rounded-lg ml-1 flex-1 bg-[#ECEEEF] p-3"}>
                <Text className="">זכר</Text>
              </button>
              <button onClick={() => setValue('gender', "f")} className={value === 'f' ? "flex-1 border border-[#4F69FF] bg-[#ECEEEF] p-3 mr-1 rounded-lg" : "rounded-lg mr-1 flex-1 bg-[#ECEEEF] p-3"}>
                <Text className="">נקבה</Text>
              </button>
            </div>
          )}
          name="gender"
        />
        {errors.gender && <Text className="text-[#FF554A]">נא לבחור מין</Text>}
      </>
    )
  }, [temporaryUser, errors]);

  return (
    <BaseScreen>
      <div className="flex flex-row justify-between items-center mb-5">
        <Text>הרשמה</Text>
        <IconButton name="arrow_back_ios" onClick={() => { navigate(-1)}} />
      </div>
      <div className="flex flex-col h-full">
        <Text className="text-3xl">כמה פרטים כללים,</Text>
        <Text className="text-3xl mb-2">יאללה!</Text>
        <div>
          <Text className="text-lg  text-[#B9BABE] my-2">אימייל</Text>
          <Input type="email" name="email" />
        </div>
        <div>
          <Text className="text-lg  text-[#B9BABE] my-2">תעודת זהות</Text>
          <Input type="tel" name="id_number" />
        </div>
        <div className="mb-auto">
          <Text className="text-lg text-[#B9BABE] my-2">מין</Text>
          <GenderSelection />
        </div>
        <Button onClick={handleSubmit(onSubmit)} text="המשך" />
      </div >
    </BaseScreen>
  )
};

export default Details;
