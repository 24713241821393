import Modal from "./Modal";
import Text from "./Text";
import Button from "./Button";
import { useCallback } from "react";
import Icon from "@mui/material/Icon";;

type NoCameraPermissionsProps = {
  setHasCameraPermissions: (value: boolean) => void;
};

const NoCameraPermissions = ({ setHasCameraPermissions }: NoCameraPermissionsProps) => {
  const openAppSettings = useCallback(async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
      setHasCameraPermissions(true);
    } catch (error) {
      console.error(error);
    }
  }, []);
  return (
    <Modal showModal={true}>
      <div className="p-5">
        <div className="text-center text-black">
          <Icon fontSize="large" color="inherit">app_settings_alt</Icon>
        </div>
        <Text className="my-5 text-xl">נשמח אם תוכל לספק לנו את הרשאות המצלמה שאנו צריכים כדי לסרוק את תעודת הזהות שלך ולאשר את זהותך.</Text>
        <div className="flex flex-row">
          <div className="flex-1 mr-1">
            <Button className="W-full" onClick={openAppSettings} text="תן הרשאות למצלמה" />
          </div>
        </div>
      </div>
    </Modal>
  )
};

export default NoCameraPermissions;