import BaseScreen from '../components/BaseScreen';
import Text from '../components/Text';
import Button from '../components/Button';
import { useNavigate } from 'react-router-dom';

const Welcome = () => {

    const navigate = useNavigate();

    return (
        <BaseScreen>
            <div className="">
                <Text className="mt-3 text-5xl text-center font-light">בנק אופרה</Text>
                <Text className="mb-auto text-xl text-center font-light">להיות ראשון זה מחייב</Text>
                <img style={{
                    width: '25%',
                    height: undefined,
                    aspectRatio: 1,
                }} className="my-10 ml-auto mr-auto" src='../../images/welcome.png' />
            </div>
            <Button className='mt-auto mb-2 from-[#ACACB0] to-[#ACACB0]' text="התחברות" onClick={() => navigate('/login')} />
            <Button text="הרשמה" onClick={() => { navigate('/names') }} />
        </BaseScreen>
    )
}

export default Welcome;