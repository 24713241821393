import { useCallback, useContext, useEffect, useState } from "react";
import API from "../services/API";
import storage from "../services/storage";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from "react-hook-form";
import AppContext from "../context/app";
import Button from "../components/Button";
import BaseScreen from '../components/BaseScreen';
import IconButton from "../components/IconButton";
import Text from "../components/Text";
import { useNavigate } from "react-router-dom";
import YupPassword from 'yup-password';

YupPassword(Yup);
let schema = Yup.object({
  phone: Yup.string().min(10).max(10).required(),
  password: Yup.string().min(6)
  .minLowercase(1, 'password must contain at least 1 lower case letter')
  .minUppercase(1, 'password must contain at least 1 upper case letter')
  .minNumbers(1, 'password must contain at least 1 number')
  .minSymbols(1, 'password must contain at least 1 special character').required(),
});

const api = new API();

const Password = () => {

  const { control, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();
  const { temporaryUser, setErrorMessage, setSuccessMessage } = useContext(AppContext)
  const [code, setCode] = useState("");
  const [verify, setVerify] = useState(false);

  const nextButton = (data: any) => {
    if (!verify) {
      sendCode(data);
      return;
    }
    createUser(data);
  }

  const sendCode = async (data: any) => {
    const temporary_user_id = await storage.getData('temporary_user_id');
    const api = new API();
    const response = await api.users.validate({ temporary_user_id, phone_number: data.phone });
    if (response.success === false) {
      setErrorMessage(response.data.message);
      navigate('/error');
      return;
    }
    setVerify(true);
  }

  const createUser = async (data: any) => {
    const temporary_user_id = await storage.getData('temporary_user_id');
    if(!temporary_user_id) {
      console.log('no temp user found');
      // act accordingly
      return;
    }
    const response = await api.users.post({ ...data, temporary_user_id, code });
    if (response.success === false) {
      setErrorMessage(response.data.message);
      navigate('/error');
      return;
    }
    setSuccessMessage("האימות בוצע בבהצלחה")
    navigate('/success')
  };

  const Input = useCallback(({ name, type = "default" }: { name: string, type?: string }) => {
    let errorMsg = errors[name]?.message?.toString();
    if (!errorMsg) errorMsg = "error";
    return (
      <>
        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ field }) => <input {...field} type={type} className="w-full p-3 text-black rounded-lg text-right bg-[#ECEEEF]" />}
          name={name}
        />
        {errors[name] && <Text className="text-[#FF554A] mt-1">{errorMsg}</Text>}
      </>
    )
  }, [control, errors]);

  const Code = useCallback(() => {
    if (!verify) return <div />;
    return (
      <div>
        <Text className="text-lg  text-[#B9BABE] my-2">קוד</Text>
        <input type="numeric" onChange={(text) => {setCode(text.target.value)}} className="w-full p-3 text-black rounded-lg text-right bg-[#ECEEEF]" />
      </div>
    )
  }, [verify]);

  useEffect(() => {
    if(!temporaryUser.credit_verified) {
      navigate('/');
    }
  }, [temporaryUser]);

  return (
    <BaseScreen>
      <div className="flex flex-row justify-between items-center mb-5">
        <Text>הרשמה</Text>
        <IconButton name="arrow_back_ios" onClick={() => navigate(-1)} />
      </div>
      <div className="flex flex-col h-full">
        <Text className="text-3xl">שלב אחרון והכל מוכן</Text>
        <Text className="text-3xl mb-2">קדימה!</Text>
        <div>
          <Text className="text-lg  text-[#B9BABE] my-2">סיסמה</Text>
          <Input name="password" type="password" />
        </div>
        <div>
          <Text className="text-lg  text-[#B9BABE] my-2">מספר טלפון</Text>
          <Input name="phone" type="numeric" />
        </div>
        <Code />
        <div className="mt-auto">
          <Button onClick={handleSubmit(nextButton)} text="המשך" />
        </div>
      </div>
    </BaseScreen>
  )

};

export default Password;