import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import BaseScreen from "../components/BaseScreen";
import Button from "../components/Button";
import IconButton from "../components/IconButton";
import Text from "../components/Text";
import Webcam from "react-webcam";
import AppContext from "../context/app";
import CantGoBack from "../components/CantGoBack";

const Scan = () => {

  const cameraRef = useRef<Webcam>(null)
  const navigate = useNavigate();
  const { temporaryUser } = useContext(AppContext);
  const [showGoBack, setGoBack] = useState(false);

  // // TODO: stop camera when presenting the photo

  const takePicture = useCallback(async () => {
    if (!cameraRef.current) return;
    let photoFile = await cameraRef.current.getScreenshot();
    if (!photoFile) {
      return;
    }
    navigate('/photo', { state: { photo: photoFile } })
  }, [cameraRef]);

  useEffect(() => {
    if (temporaryUser.credit_verified) {
      navigate('/password')
    }
  }, [temporaryUser]);

  return (
    <BaseScreen>
      <CantGoBack isVisible={showGoBack} setIsVisible={setGoBack} />
      <div className="flex flex-row justify-between items-center mb-5">
        <Text>הרשמה</Text>
        <IconButton name="arrow_back_ios" onClick={() => { setGoBack(true) }} />
      </div>
      <div className="h-full w-full">
        <div className="flex flex-col h-full">
          <div className="max-w-full bg-gray-500 rounded h-[190px] mb-2">
            <Webcam
              ref={cameraRef}
              audio={false}
              style={{ borderColor: "#4A5AFF", borderWidth: 1, borderRadius: 10, width: '100%', height: '100%' }}
            />
          </div>
          {
            temporaryUser.id_verified
              ? <Text className="font-bold text-lg text-center mb-auto">צלם את כרטיס האשראי שלך</Text>
              : <Text className="font-bold text-lg text-center mb-auto">צלם את תעודות הזהות או רישיון הנהיגה שלך</Text>
          }
          <Button onClick={takePicture} text="רואים טוב, צלם!" />
        </div>
      </div>
    </BaseScreen>
  )
}

export default Scan;