import { 
  useCallback,
  useEffect,
  useState 
} from 'react';
import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import AppContext from './context/app';
import Names from './screens/Names';
import Details from './screens/Details';
import Address from './screens/Address';
import Scan from './screens/Scan';
import Error from './screens/Error';
import Login from './screens/Login';
import Code from './screens/Code';
import Success from './screens/Success';
import Password from './screens/Password';
import storage from './services/storage';
import API from './services/API';
import TemporaryUser from './types/TemporaryUser';
import Modal from './components/Modal';
import Text from './components/Text';
import Welcome from './screens/Welcome';
import Photo from './screens/Photo';
import NoCameraPermissions from './components/NoCameraPermissions';

const router = createBrowserRouter([
  {
    path: "/names",
    element: <Names />,
  },
  {
    path: "/details",
    element: <Details />,
  },
  {
    path: "/address",
    element: <Address />,
  },
  {
    path: "/scan",
    element: <Scan />,
  },
  {
    path: "/photo",
    element: <Photo />,
  },
  {
    path: "/error",
    element: <Error />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/code",
    element: <Code />,
  },
  {
    path: "/password",
    element: <Password />,
  },
  {
    path: "/success",
    element: <Success />,
  },
  {
    path: "/",
    element: <Welcome />,
  },
]);

const api = new API();

const App = () => {
  const [hasCameraPermissions, setHasCameraPermissions] = useState(false);
  const [isOnline, setOnline] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [temporaryUser, setTemporaryUser] = useState<TemporaryUser>({
    first_name_hebrew: "",
    last_name_hebrew: "",
    first_name_english: "",
    last_name_english: "",
    email: "",
    id_number: "",
    gender: "",
    address: "",
    postal_code: "",
  });
  const context = {
    errorMessage, setErrorMessage,
    temporaryUser, setTemporaryUser,
    successMessage, setSuccessMessage
  };
  useEffect(() => {
    const loadTemporaryUser = async () => {
      const temporary_user_id = await storage.getData('temporary_user_id');
      if (!temporary_user_id) {
        await storage.remove('temporary_user_id');
        return;
      }
      const response = await api.temporaryUsers.get(temporary_user_id);
      if (response.success === false) {
        await storage.remove('temporary_user_id'); // TOOD: should we keep this line?
        return;
      } // should we display this error message?
      setTemporaryUser(response.data.temporaryUser);
    };
    loadTemporaryUser();
  }, []);

  const handleOfflineEvent = useCallback(() => {
    setOnline(false);
  }, []);

  const handleOnlineEvent = useCallback(() => {
    setOnline(false);
  }, []);

  useEffect(() => {
    window.addEventListener('online', handleOnlineEvent);
    window.addEventListener('offline', handleOfflineEvent);
    return () => {
      window.removeEventListener('onilne', handleOnlineEvent);
      window.removeEventListener('offline', handleOfflineEvent);
    };
  }, []); // make sure this on other screens as well

  useEffect(() => {
    setOnline(navigator.onLine)
  }, []);

  useEffect(() => {
    const askForPermissions = async () => {
      try {
        await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
        setHasCameraPermissions(true);
      } catch (error) {
        console.error(error);
      }
    }
    askForPermissions();
  }, [])

  if (hasCameraPermissions === false) return <NoCameraPermissions setHasCameraPermissions={setHasCameraPermissions} />

  return (
    <AppContext.Provider value={context}>
      <Modal showModal={isOnline === false}>
        <Text>חיבור האינטרנט אבד, אנא התחבר מחדש כדי להמשיך</Text>
      </Modal>
      <RouterProvider router={router} />
    </AppContext.Provider>
  );
}

export default App;
