import React, { useCallback, useContext, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import AppContext from "../context/app";
import Button from "../components/Button";
import BaseScreen from "../components/BaseScreen";
import IconButton from '../components/IconButton';
import Text from '../components/Text';
import { useNavigate } from "react-router-dom";

let schema = object({
  first_name_hebrew: string().required(),
  last_name_hebrew: string().required(),
  first_name_english: string().required(),
  last_name_english: string().required(),
});

const Names = () => {

  const { temporaryUser, setTemporaryUser } = useContext(AppContext);
  const navigate = useNavigate();

  const { control, handleSubmit, formState: { errors } }: any = useForm({
    resolver: yupResolver(schema),
    defaultValues: { ...temporaryUser }
  });

  useEffect(() => {
    if (temporaryUser._id) {
      navigate('/scan')
    }
  }, [temporaryUser]);

  const onSubmit = async (data: any) => {
    setTemporaryUser({ ...temporaryUser, ...data });
    navigate('/details');
  };

  const Input = useCallback(({ name, type = "default" }: { name: any, type?: string }) => {
    return (
      <>
        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ field }) => <input {...field}  type={type} className="w-full p-3 text-black rounded-lg text-right bg-[#ECEEEF]" />}
          name={name}
        />
        {errors[name] && <Text className="text-[#FF554A] mt-1">השדה חסר או אינו נכון</Text>}
      </>
    )
  }, [control, errors]);

  return (
    <BaseScreen>
      <div className="flex flex-row justify-between items-center mb-5">
        <Text>הרשמה</Text>
        <IconButton name="arrow_back_ios" onClick={() => { navigate(-1)}} />
      </div>
      <div className="flex flex-col">
        <Text className="text-3xl">איך קוראים לך,</Text>
        <Text className="text-3xl mb-2">וממשיכים!</Text>
        <div>
          <Text className="text-lg text-[#B9BABE] my-2">שם פרטי בעברית</Text>
          <Input name="first_name_hebrew" />
        </div>
        <div>
          <Text className="text-lg text-[#B9BABE] my-2">שם משפחה בעברית</Text>
          <Input name="last_name_hebrew" />
        </div>
        <div>
          <Text className="text-lg text-[#B9BABE] my-2">שם פרטי באנגלית</Text>
          <Input name="first_name_english" />
        </div>
        <div>
          <Text className="text-lg text-[#B9BABE] my-2">שם משפחה באנגלית</Text>
          <Input name="last_name_english" />
        </div>
      </div>
      <div className="mt-auto">
        <Button onClick={handleSubmit(onSubmit)} text="המשך" />
      </div>
    </BaseScreen>
  )
};

export default Names;
